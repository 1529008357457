var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-form"},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-1/5"},[_c('label',{staticClass:"vs-input--label"},[_vm._v(_vm._s(_vm.$t("shops.name")))]),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",class:{
          'danger-border-select': _vm.errorName && _vm.errors.has('hookName')
          },attrs:{"name":"hookName","danger":_vm.errorName && _vm.errors.has('hookName'),"danger-text":_vm.$t('shops.errors.name_is_required')},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})],1),_c('div',{staticClass:"vx-col w-1/5"},[_c('label',{staticClass:"vs-input--label"},[_vm._v(_vm._s(_vm.$t("shops.localization")))]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",class:{
          'danger-border-select': _vm.errorType && _vm.errors.has('hookType')
          },attrs:{"name":"hookType","placeholder":_vm.$t('shops.select_localization'),"value":_vm.hookSelected,"options":_vm.hookTypeOptions.map(function (option) { return ({
              label: option.label,
              value: option.value
          }); }),"danger":_vm.errorType && _vm.errors.has('hookType'),"danger-text":_vm.$t('shops.errors.type_is_required')},on:{"input":function (option) { return _vm.selectHook(option); }},model:{value:(_vm.value.type),callback:function ($$v) {_vm.$set(_vm.value, "type", $$v)},expression:"value.type"}})],1)]),_c('div',{staticClass:"vx-col 1/3"},[_c('vs-button',{staticClass:"mt-base",attrs:{"type":"filled"},on:{"click":function($event){return _vm.validateAndSubmit(_vm.value)}}},[_vm._v(_vm._s(_vm.$t("shops.save"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }